import moment from 'moment'
import {
  GET_TICKETS,
  UPDATE_CURRENT_TICKET,
  GET_LEAVE_REASONS,
  DELETE_TICKET,
  CHANGE_TICKET_OWNER,
} from '../action-types'
import api from 'Common/api'
import { handleServerError, setCookie } from 'Common/utils'

export const getTickets = () => async dispatch => {
  try {
    const res = await api.ticket.list()
    const { data, status } = res
    if (status === 202) {
      if (data.client_key)
        setCookie('client_key', data.client_key, {
          expires: moment().add(1, 'd').toDate(),
        })
      return
    }
    dispatch({ type: GET_TICKETS, payload: data.tickets })
    return data.tickets
  } catch (error) {
    return handleServerError(error)
  }
}
export const getTicket = id => async dispatch => {
  try {
    const res = await api.ticket.retrieve(id)
    return dispatch(updateCurrentTicket(res.data.ticket))
  } catch (error) {
    const { status } = error.response
    if (status === 404) return status
    return handleServerError(error)
  }
}
export const createTicket = queueId => async dispatch => {
  try {
    const res = await api.ticket.create(queueId)
    const ticket = res.data.createdTicket
    dispatch(updateCurrentTicket(ticket))
    return ticket.id
  } catch (error) {
    return handleServerError(error)
  }
}
export const updateCurrentTicket = ticket => {
  return { type: UPDATE_CURRENT_TICKET, payload: ticket }
}
export const getLeaveReasons = () => async dispatch => {
  try {
    const res = await api.ticket.abandonReasons()
    const { data, status } = res
    if (status === 204) return
    return dispatch({ type: GET_LEAVE_REASONS, payload: data.reasons })
  } catch (error) {
    return handleServerError(error)
  }
}
export const deleteTicket = ({ id, reason }) => async dispatch => {
  try {
    await api.ticket.abandon({ id, reason })
    dispatch({ type: DELETE_TICKET })
    return 200
  } catch (error) {
    return handleServerError(error)
  }
}
export const changeTicketOwner = ({ id, ticketNumber }) => async dispatch => {
  try {
    await api.ticket.changeOwner({ id, ticketNumber })
    return dispatch({ type: CHANGE_TICKET_OWNER })
  } catch (error) {
    const { status } = error.response
    if (status === 404) return status
    return handleServerError(error)
  }
}
