import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Statistic } from 'antd'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { getQueues } from 'Actions/queue-actions'

import Loader from 'Components/Loader'
import StoreName from 'Components/StoreName'
import { Text, HighlightText } from 'Common/styled'
import { getQueryParam } from 'Common/utils'

class StoreStatus extends Component {
  static propTypes = {
    getQueues: PropTypes.func,
    queues: PropTypes.arrayOf(PropTypes.object),
  }
  state = {
    isLoading: true,
  }
  componentDidMount() {
    const { history } = this.props
    const storeId = getQueryParam('storeId')
    if (storeId) {
      this.getQueues(storeId)
    } else {
      history.push('/')
    }
  }
  componentWillUnmount() {
    clearInterval(this.timer)
  }
  getQueues(storeId) {
    const { getQueues } = this.props
    getQueues(storeId).then(() => {
      this.startTimer(storeId)
      this.setState({ isLoading: false })
    })
  }
  startTimer(storeId) {
    if (this.timer) {
      clearInterval(this.timer)
    }
    this.timer = setInterval(() => this.getQueues(storeId), 15000)
  }
  render() {
    const { isLoading } = this.state
    const { queues } = this.props
    if (isLoading) return <Loader />
    if (queues.length > 0)
      return (
        <>
          <StoreName>{queues[0].storeName}</StoreName>
          <Text>
            <FormattedMessage id={'storeStatus.text'} />
          </Text>
          {queues.map(q => {
            const { queueName, ticketNumber } = q
            return (
              <Card size={'small'} key={ticketNumber}>
                <Statistic
                  title={queueName}
                  valueRender={() => (
                    <HighlightText>{ticketNumber}</HighlightText>
                  )}
                />
              </Card>
            )
          })}
        </>
      )
    return <FormattedMessage id={'storeStatus.noQueue'} />
  }
}
const mapStateToProps = state => ({
  queues: state.queue.list,
})
const mapDispatchToProps = {
  getQueues,
}
export default connect(mapStateToProps, mapDispatchToProps)(StoreStatus)
