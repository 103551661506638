import {
  UPDATE_SHOPPING_GUIDE_FAMILIES,
  UPDATE_SHOPPING_GUIDE_DETAILS,
} from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const getShoppingGuideFamilies = (shoppingGuideId, storeId) => async dispatch => {
  try {
    const res = await api.shoppingGuide.families(shoppingGuideId, storeId)
    const { families, findItemsUrl } = res.data
    return dispatch({
      type: UPDATE_SHOPPING_GUIDE_FAMILIES,
      payload: { families, findItemsUrl },
    })
  } catch (error) {
    const { status } = error.response
    if (status === 404) return status
    return handleServerError(error)
  }
}
export const getShoppingGuideDetails = familyId => async dispatch => {
  try {
    const res = await api.shoppingGuide.details(familyId)
    dispatch({ type: UPDATE_SHOPPING_GUIDE_DETAILS, payload: res.data.details })
    return 200
  } catch (error) {
    return handleServerError(error)
  }
}
