import { GET_QUEUE, GET_QUEUES } from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const getQueue = queueShortCode => async dispatch => {
  try {
    const res = await api.queue.retrieve(queueShortCode)
    return dispatch({ type: GET_QUEUE, payload: res.data.queue })
  } catch (error) {
    const { status, data } = error.response
    if (status === 404) return status
    if (status === 451) {
      dispatch({ type: GET_QUEUE, payload: data.queue })
      return status
    }
    return handleServerError(error)
  }
}
export const getQueues = storeId => async dispatch => {
  try {
    const res = await api.queue.list(storeId)
    const { data, status } = res
    if (status === 204) return
    return dispatch({ type: GET_QUEUES, payload: data.queues })
  } catch (error) {
    return handleServerError(error)
  }
}
