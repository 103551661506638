import { SEND_PHONE_NUMBER } from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const sendPhoneNumber = ({ id, phoneNumber }) => async dispatch => {
  try {
    await api.sms.submit({ id, phoneNumber })
    dispatch({ type: SEND_PHONE_NUMBER })
    return 200
  } catch (error) {
    return handleServerError(error)
  }
}
