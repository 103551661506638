export const GET_TICKETS = 'GET_TICKETS_LIST'
export const UPDATE_CURRENT_TICKET = 'UPDATE_CURRENT_TICKET'
export const GET_LEAVE_REASONS = 'GET_LEAVE_REASONS'
export const DELETE_TICKET = 'DELETE_TICKET'
export const CHANGE_TICKET_OWNER = 'CHANGE_TICKET_OWNER'

export const GET_QUEUE = 'GET_QUEUE'
export const GET_QUEUES = 'GET_QUEUES'

export const SEND_PHONE_NUMBER = 'SEND_PHONE_NUMBER'

export const UPDATE_SHOPPING_GUIDE_FAMILIES = 'UPDATE_SHOPPING_GUIDE_FAMILIES'
export const UPDATE_SHOPPING_GUIDE_DETAILS = 'UPDATE_SHOPPING_GUIDE_DETAILS'
