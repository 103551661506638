import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, message } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { getQueue } from 'Actions/queue-actions'
import { createTicket } from 'Actions/ticket-actions'

import Loader from 'Components/Loader'
import StoreName from 'Components/StoreName'
import Button from 'Components/Button'
import { Text, HighlightText } from 'Common/styled'

import { getQueryParam, getCookie } from 'Common/utils'
import { QUEUE_OPEN_STATUS, QUEUE_CLOSE_STATUS } from 'Common/constants'

class NewTicket extends Component {
  static propTypes = {
    queue: PropTypes.object,
    getQueue: PropTypes.func,
  }
  state = {
    isLoading: false,
  }
  componentDidMount() {
    const { history } = this.props
    const queueShortCode = getQueryParam('queueShortCode')
    if (queueShortCode) {
      this.getQueue(queueShortCode)
    } else {
      const client_key = getCookie('client_key')
      if (!client_key) history.push('/')
    }
  }
  onSubmitQueue = () => {
    const queueShortCode = document.getElementById('queue-short-code').value
    if (queueShortCode) {
      this.getQueue(queueShortCode)
    }
  }
  getQueue(queueShortCode) {
    const { intl, getQueue } = this.props
    this.setState({ isLoading: true })
    getQueue(queueShortCode).then(res => {
      const newState = { isLoading: false }
      if (res === 404) {
        message.config({ duration: 5, top: 100 })
        message.error(
          intl.formatMessage({ id: 'newTicket.error.notFoundQueue' })
        )
      } else {
        if (res === 451) newState.invalidQueue = true
        newState.queueShortCode = queueShortCode
      }
      this.setState(newState)
    })
  }
  onSubmitTicket = () => {
    const { history, queue, createTicket } = this.props
    this.setState({ isLoading: true })
    createTicket(queue.queueId).then(ticketId => {
      history.push(`/queue-status/${ticketId}`)
    })
  }
  renderQueue() {
    const { invalidQueue } = this.state
    const { history, queue } = this.props

    if (invalidQueue) {
      return (
        <>
          <div>
            <Text>
              <FormattedMessage id={'newTicket.invalidQueue.text'} />
            </Text>
            <StoreName>{queue.storeName}</StoreName>
          </div>
          <div>
            <Text>
              <FormattedMessage id={'newTicket.department'} />
            </Text>
            <HighlightText>{queue.queueName}</HighlightText>
          </div>
          <Button type={'primary'} ghost onClick={() => history.push('/')}>
            <FormattedMessage id={'newTicket.button.goToHome'} />
          </Button>
        </>
      )
    }
    if (queue.status === QUEUE_OPEN_STATUS) {
      return (
        <>
          <div>
            <Text>
              <FormattedMessage id={'newTicket.openQueue.text'} />
            </Text>
            <StoreName>{queue.storeName}</StoreName>
          </div>
          <div>
            <Text>
              <FormattedMessage id={'newTicket.department'} />
            </Text>
            <HighlightText>{queue.queueName}</HighlightText>
          </div>
          <Button type={'primary'} onClick={this.onSubmitTicket}>
            <FormattedMessage id={'newTicket.button.submitTicket'} />
          </Button>
        </>
      )
    }
    if (queue.status === QUEUE_CLOSE_STATUS) {
      return (
        <>
          <div>
            <Text>
              <FormattedMessage id={'newTicket.closedQueue.text_1'} />
            </Text>
            <StoreName>{queue.storeName}</StoreName>
          </div>
          <div>
            <Text>
              <FormattedMessage id={'newTicket.department'} />
            </Text>
            <HighlightText>{queue.queueName}</HighlightText>
          </div>
          <Text>
            <FormattedMessage id={'newTicket.closedQueue.text_2'} />
          </Text>
          <Text>
            <FormattedMessage id={'newTicket.closedQueue.cta'} />
          </Text>
        </>
      )
    }

    return <FormattedMessage id={'newTicket.noQueue'} />
  }
  render() {
    const { isLoading, queueShortCode } = this.state
    const { intl } = this.props
    if (isLoading) return <Loader />
    if (queueShortCode) return this.renderQueue()
    return (
      <>
        <Text>
          <FormattedMessage id={'newTicket.text'} />
        </Text>
        <Input
          id={'queue-short-code'}
          maxLength={3}
          size={'large'}
          placeholder={intl.formatMessage({
            id: 'newTicket.input.placeholder',
          })}
        />

        <Button type={'primary'} onClick={this.onSubmitQueue}>
          <FormattedMessage id={'newTicket.button.submitQueue'} />
        </Button>
      </>
    )
  }
}
const mapStateToProps = state => ({
  queue: state.queue.current,
})
const mapDispatchToProps = {
  getQueue,
  createTicket,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(NewTicket))
