import React from 'react'
import { Icon } from 'antd'

import { HighlightText } from 'Common/styled'
import { colors } from 'Common/constants'

export default props => (
  <HighlightText>
    {props.children}{' '}
    <Icon
      type={'environment'}
      theme={'filled'}
      style={{ color: colors.main }}
    />
  </HighlightText>
)
