import API from './axiosConfig'
import { getCookie } from './utils'

const setHeaders = () => {
  const headers = {}
  const client_key = getCookie('client_key')
  if (client_key) headers.client_key = client_key
  return headers
}

export default {
  ticket: {
    list: () => API.get('/customers_ticket/list', { headers: setHeaders() }),
    abandonReasons: () =>
      API.get('/customers_ticket/abandonReason', { headers: setHeaders() }),
    abandon: ({ id, reason }) =>
      API.post(
        '/customers_ticket/abandon',
        { id, reason },
        { headers: setHeaders() }
      ),
    create: queueId =>
      API.post(
        '/mixed_ticket/create',
        { queueId, creationType: 'SELF' },
        { headers: setHeaders() }
      ),
    retrieve: id =>
      API.get('/customers_ticket/retrieve', {
        params: { id },
        headers: setHeaders(),
      }),
    changeOwner: ({ id, ticketNumber }) =>
      API.post(
        '/customers_ticket/changeOwner',
        { id, ticketNumber },
        { headers: setHeaders() }
      ),
  },
  queue: {
    retrieve: queueShortCode =>
      API.get('/customers_queue/retrieve', {
        params: { queueShortCode },
        headers: setHeaders(),
      }),
    list: storeId =>
      API.get('/customers_queue/list', {
        params: { storeId },
        headers: setHeaders(),
      }),
  },
  sms: {
    submit: ({ id, phoneNumber }) =>
      API.post(
        '/mixed_sms/submit',
        { id, phoneNumber },
        { headers: setHeaders() }
      ),
  },
  shoppingGuide: {
    families: (shoppingGuideId, storeId) =>
      API.get('/customers_shopping_guide/families', {
        params: { shoppingGuideId, storeId },
        headers: setHeaders(),
      }),
    details: familyId =>
      API.get('/customers_shopping_guide/details', {
        params: { familyId },
        headers: setHeaders(),
      }),
  },
}
