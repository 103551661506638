import {
  UPDATE_SHOPPING_GUIDE_FAMILIES,
  UPDATE_SHOPPING_GUIDE_DETAILS,
} from '../action-types'
export default (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_SHOPPING_GUIDE_FAMILIES:
      return {
        ...state,
        ...payload, // { families, findItemsUrl }
      }
    case UPDATE_SHOPPING_GUIDE_DETAILS:
      return {
        ...state,
        details: payload,
      }
    default:
      return state
  }
}
