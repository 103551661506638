import React from 'react'
import PropTypes from 'prop-types'
const QueuePosition = props => {
  const { positionImg } = props
  return (
    <img
      src={`/assets/img/queue-position/${positionImg}`}
      alt={'queue position'}
      width={'100%'}
    />
  )
}
QueuePosition.propTypes = {
  positionImg: PropTypes.string.isRequired,
}
export default QueuePosition
