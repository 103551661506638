import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Loader from 'Components/Loader'
const Video = props => {
  const { src } = props
  const [isLoading, setIsLoading] = useState(true)
  const display = isLoading ? 'none' : 'block'
  return (
    <>
      {isLoading ? <Loader height={'200px'} /> : null}
      <iframe
        style={{ display }}
        title={'product-video'}
        src={src}
        width={'100%'}
        height={200}
        frameBorder={0}
        allowFullScreen
        onLoad={() => setIsLoading(false)}
      />
    </>
  )
}
Video.propTypes = {
  src: PropTypes.string.isRequired,
}
export default Video
