import React, { Component } from 'react'
import { Switch, Route, Link } from 'react-router-dom'

import Home from 'Containers/Home'
import NewTicket from 'Containers/NewTicket'
import LeaveQueue from 'Containers/LeaveQueue'
import QueueStatus from 'Containers/QueueStatus'
import ChangeOwner from 'Containers/ChangeOwner'
import ShoppingGuide from 'Containers/ShoppingGuide'
import StoreStatus from 'Containers/StoreStatus'
import Page404 from 'Containers/404'
import Footer from 'Components/Footer'
import { WhiteLayout, PaddedContent, AppLogo } from 'Common/styled'
import { getQueryParam } from 'Common/utils'

class App extends Component {
  static propTypes = {}

  renderLogo() {
    const storeId = getQueryParam('storeId')
    if (storeId) {
      return <AppLogo src={'/assets/img/logo.png'} alt={'logo'} height={56} />
    } else {
      return (
        <Link to={'/'}>
          <AppLogo src={'/assets/img/logo.png'} alt={'logo'} height={56} />
        </Link>
      )
    }
  }
  render() {
    return (
      <WhiteLayout>
        {this.renderLogo()}
        <PaddedContent>
          <Switch>
            <Route component={Home} exact path={'/'} />
            <Route component={NewTicket} path={'/new-ticket'} />
            <Route component={LeaveQueue} path={'/leave-queue'} />
            <Route component={QueueStatus} path={'/queue-status/:ticketId'} />
            <Route component={ChangeOwner} path={'/change-owner/:ticketId'} />
            <Route component={ShoppingGuide} path={'/shopping-guide'} />
            <Route component={StoreStatus} path={'/status'} />
            <Route component={Page404} />
          </Switch>
        </PaddedContent>
        <Footer />
      </WhiteLayout>
    )
  }
}
export default App
