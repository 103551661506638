import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { Select } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { getLeaveReasons, deleteTicket } from 'Actions/ticket-actions'

import Loader from 'Components/Loader'
import Ticket from 'Components/Ticket'
import Button from 'Components/Button'
import { Text, SmallText, HighlightText } from 'Common/styled'

class LeaveQueue extends Component {
  static propTypes = {
    currentTicket: PropTypes.object,
    deleteTicket: PropTypes.func,
    getLeaveReasons: PropTypes.func,
    reasons: PropTypes.array,
  }
  state = {
    isLoading: true,
  }
  componentDidMount() {
    const { history, currentTicket, getLeaveReasons } = this.props
    if (isEmpty(currentTicket)) {
      history.push('/')
    } else {
      getLeaveReasons().then(() => this.setState({ isLoading: false }))
    }
  }
  onSubmitLeave = () => {
    const { reason } = this.state
    const { currentTicket, deleteTicket } = this.props
    this.setState({ isLoading: true })
    deleteTicket({ id: currentTicket.id, reason }).then(res => {
      const newState = { isLoading: false }
      if (res === 200) newState.leaveSuccess = true
      this.setState(newState)
    })
  }
  onSelectReason = id => this.setState({ reason: id })
  renderFeedback() {
    const { history } = this.props
    return (
      <>
        <HighlightText>
          <FormattedMessage id={'leaveQueue.feedback.title'} />
        </HighlightText>
        <Text>
          <FormattedMessage id={'leaveQueue.feedback.text'} />
        </Text>
        <Button type={'primary'} onClick={() => history.push('/')}>
          <FormattedMessage id={'leaveQueue.button.goToHome'} />
        </Button>
      </>
    )
  }
  renderLeaveReasons() {
    const { intl, reasons } = this.props
    return (
      <>
        <Text>
          <FormattedMessage id={'leaveQueue.reason.text'} />
        </Text>
        <Select
          placeholder={intl.formatMessage({
            id: 'leaveQueue.reason.placeholder',
          })}
          onChange={this.onSelectReason}>
          {reasons.map(r => {
            const { id, reason } = r
            return (
              <Select.Option key={id} className={'reason'}>
                {reason}
              </Select.Option>
            )
          })}
        </Select>
      </>
    )
  }
  renderLeaveForm() {
    const { reasons, currentTicket } = this.props
    return (
      <>
        <HighlightText>
          <FormattedMessage id={'leaveQueue.title'} />
        </HighlightText>
        <Ticket number={currentTicket.ticketNumber} />
        {reasons.length > 0 ? this.renderLeaveReasons() : null}
        <SmallText>
          <FormattedMessage id={'leaveQueue.text'} />
        </SmallText>
        <Button type={'danger'} ghost onClick={this.onSubmitLeave}>
          <FormattedMessage id={'leaveQueue.button.leaveQueue'} />
        </Button>
      </>
    )
  }
  render() {
    const { isLoading, leaveSuccess } = this.state
    if (isLoading) return <Loader />
    if (leaveSuccess) return this.renderFeedback()
    return this.renderLeaveForm()
  }
}
const mapStateToProps = state => ({
  reasons: state.ticket.leaveReasons,
  currentTicket: state.ticket.current,
})
const mapDispatchToProps = {
  getLeaveReasons,
  deleteTicket,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LeaveQueue))
