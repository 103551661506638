const env = process.env.REACT_APP_ENV
const envConfig = {
  dev: {
    API_URL: 'https://lmit-queuemanagement-dev-moq7sbsxma-ew.a.run.app/api/v1',
  },
  test: {
    API_URL: 'https://gestionecode-api-test.cloud.leroymerlin.it/api/v1',
  },
  prod: {
    API_URL: 'https://gestionecode-api.cloud.leroymerlin.it/api/v1',
  },
}

export default envConfig[env]
