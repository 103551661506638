import styled from 'styled-components'
export const TicketWrapper = styled.div`
  position: relative;
`
export const TicketImg = styled.img`
  width: 200px;
`
export const TicketNumber = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  font-weight: bold;
`
