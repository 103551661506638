import { GET_QUEUE, GET_QUEUES } from '../action-types'

const initialState = {
  list: [],
}
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_QUEUE:
      return {
        ...state,
        current: payload,
      }
    case GET_QUEUES:
      return {
        ...state,
        list: payload,
      }
    default:
      return state
  }
}
