import React from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'antd'
import { TicketRadio } from './styled'

const { Group } = Radio

const TicketsList = props => {
  const { data, selected, onChangeCallback } = props
  return (
    <Group defaultValue={selected} onChange={onChangeCallback}>
      {data.map(ticket => {
        const { id, queueName, ticketNumber, storeName } = ticket
        return (
          <TicketRadio key={id} value={id}>
            <strong>{`[${ticketNumber}]`}</strong>{' '}
            {`${queueName} - ${storeName}`}
          </TicketRadio>
        )
      })}
    </Group>
  )
}
TicketsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onChangeCallback: PropTypes.func,
  selected: PropTypes.number,
}
export default TicketsList
