import {
  GET_TICKETS,
  UPDATE_CURRENT_TICKET,
  GET_LEAVE_REASONS,
  DELETE_TICKET,
} from '../action-types'

const initialState = {
  leaveReasons: [],
  current: {},
}
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TICKETS:
      return {
        ...state,
        list: payload,
      }
    case UPDATE_CURRENT_TICKET:
      return {
        ...state,
        current: payload,
      }
    case GET_LEAVE_REASONS:
      return {
        ...state,
        leaveReasons: payload,
      }
    case DELETE_TICKET:
      return {
        ...state,
        current: initialState.current,
      }
    default:
      return state
  }
}
