import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getTickets, changeTicketOwner } from 'Actions/ticket-actions'

import Loader from 'Components/Loader'
import { getQueryParam } from 'Common/utils'

class ChangeOwner extends Component {
  state = {
    isLoading: true,
  }
  async componentDidMount() {
    const { history, match, getTickets, changeTicketOwner } = this.props
    const id = match.params.ticketId
    const ticketNumber = getQueryParam('ticket')
    
    await getTickets()
    const res = await changeTicketOwner({ id, ticketNumber })
    
    if (res === 404) {
      history.push('/')
    } else {
      history.push(`/queue-status/${id}`)
    }
  }
  render() {
    const { isLoading } = this.state
    return isLoading ? <Loader /> : ''
  }
}
const mapStateToProps = state => ({})
const mapDispatchToProps = {
  getTickets,
  changeTicketOwner,
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangeOwner)
