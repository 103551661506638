import React from 'react'
import PropTypes from 'prop-types'
import Button from 'Components/Button'
const FamiliesList = props => {
  const { families, onSelectCallback } = props
  return families.map(({ id, familyName }) => (
    <Button type={'primary'} key={id} value={id} onClick={onSelectCallback}>
      {familyName}
    </Button>
  ))
}
FamiliesList.propTypes = {
  families: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectCallback: PropTypes.func.isRequired,
}
export default FamiliesList
