import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'Common/styled'
const QuestionsList = props => {
  const { questions } = props
  return (
    <ul style={{ textAlign: 'left' }}>
      {questions.map((question, index) => (
        <li key={index}>
          <Text>{question}</Text>
        </li>
      ))}
    </ul>
  )
}
QuestionsList.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.string).isRequired,
}
export default QuestionsList
