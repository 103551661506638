import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { Divider, Modal, Icon } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import {
  getShoppingGuideFamilies,
  getShoppingGuideDetails,
} from 'Actions/shopping-guide-actions'

import Loader from 'Components/Loader'
import Button from 'Components/Button'
import FamiliesList from 'Components/FamiliesList'
import QuestionsList from 'Components/QuestionsList'
import Video from 'Components/Video'
import { Text, HighlightText } from 'Common/styled'

import { getQueryParam } from 'Common/utils'

class ShoppingGuide extends Component {
  static propTypes = {
    currentTicket: PropTypes.object,
    details: PropTypes.object,
    families: PropTypes.arrayOf(PropTypes.object),
    getShoppingGuideDetails: PropTypes.func,
    getShoppingGuideFamilies: PropTypes.func,
  }
  state = {
    isLoading: true,
    hasDetails: false,
  }
  componentDidMount() {
    const { history, currentTicket, getShoppingGuideFamilies } = this.props
    const shoppingGuideId = getQueryParam('id')
    if (shoppingGuideId) {
      getShoppingGuideFamilies(shoppingGuideId, currentTicket.storeId).then(
        res => {
          if (res === 404) {
            history.push('/')
          } else {
            this.setState({ isLoading: false })
          }
        }
      )
    } else {
      history.push('/')
    }
  }
  onSelectFamily = e => {
    const { getShoppingGuideDetails } = this.props
    const familyId = e.target.value
    this.setState({ isLoading: true })
    getShoppingGuideDetails(familyId).then(res => {
      const newState = { isLoading: false }
      if (res === 200) newState.hasDetails = true
      this.setState(newState)
    })
  }
  goToFindItems = () => {
    const { intl, findItemsUrl } = this.props
    Modal.confirm({
      icon: <Icon type="file-search" />,
      title: intl.formatMessage({ id: 'shoppingGuide.findItems.title' }),
      content: intl.formatMessage({ id: 'shoppingGuide.findItems.text' }),
      okText: intl.formatMessage({ id: 'shoppingGuide.findItems.okText' }),
      cancelText: intl.formatMessage({
        id: 'shoppingGuide.findItems.cancelText',
      }),
      onOk: () => {
        window.open(findItemsUrl, 'tab')
        return
      },
    })
  }
  renderFamilies() {
    const { families, findItemsUrl } = this.props
    return (
      <>
        <HighlightText>
          <FormattedMessage id={'shoppingGuide.families.title'} />
        </HighlightText>
        <FamiliesList
          families={families}
          onSelectCallback={this.onSelectFamily}
        />
        {findItemsUrl ? (
          <Button type={'primary'} onClick={this.goToFindItems}>
            <FormattedMessage id={'shoppingGuide.button.otherProducts'} />
          </Button>
        ) : null}
        {this.renderButtons()}
      </>
    )
  }
  renderDetails() {
    const { details } = this.props
    return (
      <>
        <HighlightText>
          <FormattedMessage id={'shoppingGuide.details.title'} />
        </HighlightText>
        <Text>
          <FormattedMessage id={'shoppingGuide.details.text'} />
        </Text>
        <QuestionsList questions={details.questions} />
        {details.video ? this.renderVideo() : null}
        {this.renderButtons()}
      </>
    )
  }
  renderVideo() {
    const { details } = this.props
    return (
      <>
        <HighlightText>
          <FormattedMessage id={'shoppingGuide.video.title'} />
        </HighlightText>
        <Video src={details.video} />
      </>
    )
  }
  renderButtons() {
    const { hasDetails } = this.state
    const { history, currentTicket } = this.props
    return (
      <>
        <Divider style={{ margin: 8 }} />
        {hasDetails ? (
          <Button
            type={'primary'}
            onClick={() => this.setState({ hasDetails: false })}>
            <FormattedMessage id={'shoppingGuide.button.goToFamilies'} />
          </Button>
        ) : null}
        {isEmpty(currentTicket) ? (
          <Button type={'primary'} ghost onClick={() => history.push('/')}>
            <FormattedMessage id={'shoppingGuide.button.goToHome'} />
          </Button>
        ) : (
          <Button
            type={'primary'}
            ghost
            onClick={() => history.push(`/queue-status/${currentTicket.id}`)}>
            <FormattedMessage id={'shoppingGuide.button.goToQueueStatus'} />
          </Button>
        )}
      </>
    )
  }
  render() {
    const { isLoading, hasDetails } = this.state
    if (isLoading) return <Loader />
    if (hasDetails) return this.renderDetails()
    return this.renderFamilies()
  }
}
const mapStateToProps = state => ({
  currentTicket: state.ticket.current,
  findItemsUrl: state.shoppingGuide.findItemsUrl,
  families: state.shoppingGuide.families,
  details: state.shoppingGuide.details,
})
const mapDispatchToProps = {
  getShoppingGuideFamilies,
  getShoppingGuideDetails,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ShoppingGuide))
