import React from 'react'
import PropTypes from 'prop-types'
import { TicketWrapper, TicketNumber, TicketImg } from './styled'
const Ticket = props => {
  const { number } = props
  return (
    <TicketWrapper>
      <TicketNumber>{number}</TicketNumber>
      <TicketImg src={'/assets/img/ticket.png'} alt={'ticket'} />
    </TicketWrapper>
  )
}
Ticket.propTypes = {
  number: PropTypes.string.isRequired,
}
export default Ticket
