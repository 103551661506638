import { combineReducers } from 'redux'
import app from './app-reducer'
import queue from './queue-reducer'
import ticket from './ticket-reducer'
import shoppingGuide from './shopping-guide-reducer'

const combinedReducers = combineReducers({
  app,
  queue,
  ticket,
  shoppingGuide,
})

export default combinedReducers
