import { notification } from 'antd'
import cookies from 'Common/cookiesConfig'

export const getQueryParam = param => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param)
}
export const feedbackNotification = (type, title, subtitle) => {
  return notification[type]({
    message: title,
    description: subtitle,
  })
}
export const handleServerError = error => {
  if (error.response) {
    switch (error.response.status) {
      case 400:
        return feedbackNotification(
          'error',
          'Invio non riuscito',
          'Controlla i parametri e riprova'
        )
      default:
        return feedbackNotification(
          'error',
          'Si è verificato un errore',
          'Riprova'
        )
    }
  }
  return feedbackNotification('error', 'Si è verificato un errore', 'Riprova')
}

// COOKIES
export const getAllCookies = () => {
  return cookies.getAll()
}
export const getCookie = name => {
  return cookies.get(name)
}
export const setCookie = (name, value, options) => {
  return cookies.set(name, value, options)
}
