import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { getTickets, updateCurrentTicket } from 'Actions/ticket-actions'

import Loader from 'Components/Loader'
import Button from 'Components/Button'
import TicketsList from 'Components/TicketsList'
import { Text, HighlightText } from 'Common/styled'

import { getQueryParam } from 'Common/utils'

class Home extends Component {
  static propTypes = {
    getTickets: PropTypes.func,
    currentTicket: PropTypes.object,
    tickets: PropTypes.arrayOf(PropTypes.object),
    updateCurrentTicket: PropTypes.func,
  }
  state = {
    isLoading: true,
  }
  componentDidMount() {
    const { getTickets } = this.props
    const queueShortCode = getQueryParam('queueShortCode')
    getTickets().then(tickets => {
      if (tickets) {
        this.setState({ isLoading: false })
      } else {
        this.goToNewTicket(queueShortCode)
      }
    })
  }
  goToNewTicket = queueShortCode => {
    const { history } = this.props
    history.push({
      pathname: '/new-ticket',
      search: queueShortCode ? `?queueShortCode=${queueShortCode}` : '',
    })
  }
  onSelectTicket = e => {
    const { tickets, updateCurrentTicket } = this.props
    const ticket = tickets.find(t => t.id === e.target.value)
    updateCurrentTicket(ticket)
  }
  render() {
    const { isLoading } = this.state
    const { history, tickets, currentTicket } = this.props
    const queueShortCode = getQueryParam('queueShortCode')
    if (isLoading) return <Loader />
    return (
      <>
        <HighlightText>
          <FormattedMessage id={'home.title'} />
        </HighlightText>
        <Text>
          <FormattedMessage id={'home.text'} />
        </Text>
        <TicketsList
          data={tickets}
          selected={currentTicket.id}
          onChangeCallback={this.onSelectTicket}
        />
        <Button
          type={'primary'}
          ghost
          disabled={isEmpty(currentTicket)}
          onClick={() => history.push(`/queue-status/${currentTicket.id}`)}>
          <FormattedMessage id={'home.button.queueStatus'} />
        </Button>
        <Button
          type={'danger'}
          ghost
          disabled={isEmpty(currentTicket)}
          onClick={() => history.push('/leave-queue')}>
          <FormattedMessage id={'home.button.leaveQueue'} />
        </Button>
        <Text>
          <FormattedMessage id={'home.or'} />
        </Text>
        <Button
          type={'primary'}
          onClick={() => this.goToNewTicket(queueShortCode)}>
          <FormattedMessage id={'home.button.newTicket'} />
        </Button>
      </>
    )
  }
}

const mapStateToProps = state => ({
  tickets: state.ticket.list,
  currentTicket: state.ticket.current,
})
const mapDispatchToProps = {
  getTickets,
  updateCurrentTicket,
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)
